<template>
  <list-page>
    <div class="page-filters">
      <div class="columns">
        <div class="column">
          <b-field :label="$t('ESTIMATES.VENDOR.RESPONSE.FIELDS.estimate')">
            <b-input v-model="filters.estimate_q" expanded />
          </b-field>
        </div>

        <div class="column" v-if="slateAuth.perms.rfq_view_all">
          <b-field :label="$t('ESTIMATES.VENDOR.RESPONSE.FIELDS.vendor')">
            <b-input v-model="filters.vendor" expanded />
          </b-field>
        </div>

        <div class="column">
          <b-field :label="$t('ESTIMATES.VENDOR.RESPONSE.FIELDS.order_by')">
            <b-select v-model="filters.order_by" placeholder="Select Field to Order by" expanded>
              <option value="-estimate__number">Estimate Number</option>
              <option value="status">Status</option>
              <option value="estimate__quote_due">Quote Due (soonest)</option>
              <option value="-estimate__quote_due">Quote Due (latest)</option>
            </b-select>

          </b-field>
        </div>

        <div class="column">
          <b-field label="Status">
              <b-taginput
                  placeholder="select statuses to filter by"
                  v-model="filters.status"
                  :data="statusList"
                  autocomplete
                  ref="taginput"
                  :allow-new="false"
                  :open-on-focus="true"
                  @typing="getFilteredTags"
                  expanded>
                  <template slot-scope="props">
                  {{$t(`ESTIMATES.VENDOR.RESPONSE.STATUS.${props.option}`)}}
                  </template>

                <template #selected="props">
                    <b-tag
                        v-for="(tag, index) in props.tags"
                        :key="index"
                        :tabstop="false"
                        ellipsis
                        closable
                        @close="$refs.taginput.removeTag(index, $event)">
                        {{$t(`ESTIMATES.VENDOR.RESPONSE.STATUS.${tag}`)}}
                    </b-tag>
                </template>


              </b-taginput>
          </b-field>
        </div>
      </div>
      <br />
    </div>

    <b-table :data="estimateResponses.list" :loading="loading">
      <template v-slot:default="props">
        <b-table-column
          v-for="column in columns"
          :key="column.fieldName"
          :field="column.fieldName"
          :label="column.label"
          :class="column.class"
          :visible="activeColumns.includes(column.fieldName)"
          :width="column.width"
          sortable
        >
          <div v-if="column.to" style="max-width: 20em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
            <router-link
              :to="resolveTo(column, props.row)"
              :class="column.fieldClass"
              >{{ resolveData(column, props.row) }}</router-link
            >
          </div>
          <span v-else-if="column.fieldName == 'notification_count'"
            ><icon icon="comments fa-fw" v-if="props.row.notification_count"
          /></span>
          <span v-else-if="column.fieldName == 'estimate_info.priority'">
            <span v-if="props.row.estimate_info.priority" class="has-text-danger"><icon icon="exclamation-triangle fa-fw"/> High</span>
          </span>
          <div v-else :class="column.fieldClass" style="max-width: 15em; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{
            resolveData(column, props.row)
          }}</div>
        </b-table-column>
      </template>
    </b-table>
    <hr />
    <b-pagination
      :total="estimateResponses.totalItems"
      :current.sync="filters.page"
      :per-page="filters.count"
      range-before="2"
      range-after="2"
    >
    </b-pagination>
  </list-page>
</template>

<script>
import { get } from 'lodash';
import VendorEstimateResponseList from '../../api/estimates/vendorEstimateResponseList';

const editLink = { name: 'vendorEstimateResponseEdit', params: { id: 'id' } };

export default {
  name: 'SlateVendorEstimateResponsePage',
  components: {},
  data: () => ({
    statusList: ['draft', 'pending', 'ready', 'declined', 'accepted', 'rejected', 'expired', 'cancelled', 'void'],
    filteredStatus: [],
    columns: [
      { label: 'Status', fieldName: 'status', fieldClass: { tag: true, 'is-info': true } },
      {
        label: 'Estimate#',
        fieldName: 'estimate_info.number',
        class: '{mono}',
        to: editLink,
      },
      { label: 'Priority', fieldName: 'estimate_info.priority' },
      { label: 'Vendor', fieldName: 'vendor_info.name', width: '25%' },
      { label: 'Client', fieldName: 'client_info.name', wdith: '15%' },
      {
        label: 'Name', fieldName: 'estimate_info.name', to: editLink, width: '25%',
      },
      { label: 'Due Date', fieldName: 'estimate_info.quote_due', type: 'date' },
      { label: 'Created', fieldName: 'created', type: 'date' },
    ],
    activeColumns: ['status', 'estimate_info.number', 'estimate_info.priority', 'estimate_info.name', 'estimate_info.quote_due', 'created'],
    loading: false,
    estimateResponses: new VendorEstimateResponseList(),
    filters: {
      page: 1,
      count: 50,
    },
  }),
  methods: {
    getField: get,
    resolveData(column, obj) {
      const objValue = get(obj, column.fieldName);
      if (column.type === 'date' && objValue instanceof Date) {
        return objValue.toLocaleDateString(undefined, { dateStyle: 'medium' });
      }
      if (column.type === 'date' && typeof objValue === 'string') {
        const parts = objValue.split('-');
        const tempObj = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10), 0, 0, 0);
        return tempObj.toLocaleDateString(undefined, { dateStyle: 'medium' });
      }

      /*
      if (column.fieldName === 'status') {
        return this.$t(`RFQS.RESPONSE.STATUS.${get(obj, column.fieldName)}`);
      }
      */

      return objValue;
    },
    resolveTo(column, obj) {
      if (typeof column.to === 'string') {
        return column.to;
      }
      const to = {};
      to.name = column.to.name;
      to.params = {};
      Object.keys(column.to.params).forEach((item) => {
        to.params[item] = get(obj, column.to.params[item]);
      });
      return to;
    },
    getData() {
      this.loading = true;
      this.estimateResponses
        .getList(this.filters)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          if (error.cancelled) {
            return this;
          }

          throw error;
        });
    },
    getFilteredTags(text) {
      if (!this.statusList) { return false; }
      this.filteredStatus = this.statusList.filter((option) => option.indexOf(text.toLowerCase()) >= 0);
      return true;
    },
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
    'slateAuth.perms.rfq_view_all': {
      handler() {
        if (this.slateAuth.perms.rfq_view_all && this.activeColumns.indexOf('vendor_info.name') < 0) {
          this.activeColumns.splice(this.activeColumns.indexOf('estimate_info.name'), 0, 'vendor_info.name');
        }
      },
      immediate: true,
    },
    'slateAuth.perms.vendor_estimate_responses_can_see_client': {
      handler() {
        if (this.slateAuth.perms.vendor_estimate_responses_can_see_client && this.activeColumns.indexOf('client_info.name') < 0) {
          this.activeColumns.splice(this.activeColumns.indexOf('estimate_info.name'), 0, 'client_info.name');
        }
      },
      immediate: true,
    },
  },
  mounted() {

  },
};
</script>
