<template>
  <page :loading="loading" :key="response.id">
    <template v-slot:header>
      <header class="columns">
        <div class="column is-2">
          <b-field :label="$t('ESTIMATES.VENDOR.FIELDS.number')">
            <b-input
              v-model="response.estimate_info.number"
              size="is-large"
              readonly
              :disabled="!response.isEditable"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field :label="$t('ESTIMATES.VENDOR.FIELDS.name')">
            <b-input
              v-model="response.estimate_info.name"
              size="is-large"
              readonly
              :disabled="!response.isEditable"
            />
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$t('ESTIMATES.VENDOR.RESPONSE.FIELDS.status')">
            <b-select
              v-model="response.status"
              size="is-large"
              expanded
              :disabled="!response.isEditable"
            >
              <option value disabled hidden>
                {{ $t("ESTIMATES.VENDOR.RESPONSE.FIELDS.status_placeholder") }}
              </option>
              <option
                v-for="option in response.availStatusChoices"
                :value="option"
                :key="option"
                :disabled="!response.validStatusChoices.includes(option)"
              >
                {{ $t(`ESTIMATES.VENDOR.RESPONSE.STATUS.${option}`) }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="‎">
            <!-- There is a unicode l-to-r mark in that string -->

            <button
              class="button is-primary is-large is-fullwidth"
              @click="saveData()"
              :disabled="saving || !response.isEditable"
            >
              <icon v-if="saving" icon="spinner fa-spin" />
              <icon v-else icon="save" />
              <span v-if="saving">{{ $t("APP.SAVING") }}</span>
              <span v-else>{{ $t("APP.SAVE") }}</span>
            </button>
          </b-field>
        </div>
      </header>
    </template>

    <template v-slot:bare>
      <section class="obj-details columns">
        <div class="column is-8">
          <div class="box">
                <article class="message is-warning" v-if="estimate.priority">
                  <div class="message-header">
                    <p><icon icon="exclamation-triangle fa-fw"/> High Priority!</p>
                  </div>
                  <div class="message-body">
                    This estimate request is considered a priority by Jansy. Please address it before other estimates requested by Jansy. Thank You.
                  </div>
                </article>
            <div class="columns">
              <div class="column">

                <h5>{{ $t("ESTIMATES.VENDOR.RESPONSE.SLATE_DETAILS") }}</h5>
                <hr />

                <table class="table">
                  <tr>
                    <th>{{ $t("ESTIMATES.VENDOR.FIELDS.order") }}</th>
                    <td>
                      <span
                        >#{{
                          estimate.order_info.project_info.composite_num
                        }}-OR{{ estimate.order_info.order_number }}
                        {{ estimate.order_info.name }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("ESTIMATES.VENDOR.FIELDS.quote_due") }}</th>
                    <td><span v-if="estimate.quote_due">{{ estimate.quote_due.toLocaleDateString(undefined, { dateStyle: 'medium' }) }}</span></td>
                  </tr>
                  <tr>
                    <th>{{ $t("ESTIMATES.VENDOR.FIELDS.proj_mgr") }}</th>
                    <td>{{ estimate.proj_mgr_info.full_name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("ESTIMATES.VENDOR.FIELDS.sales_mgr") }}</th>
                    <td>{{ estimate.sales_mgr_info.full_name }}</td>
                  </tr>
                </table>
              </div>
              <br />
              <br />
              <div class="column">
                <h5>
                  {{ $t("ESTIMATES.VENDOR.RESPONSE.FILES_FROM_ESTIMATE") }}
                </h5>
                <hr />
                <slate-file-attachments
                  parent-type="vendorestimate"
                  :parent-fk="response.estimate"
                  ref="estimateFileArea"
                />
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <h5>{{ $t("ESTIMATES.VENDOR.RESPONSE.REQUEST_DETAILS") }}</h5>
                <hr />

                <div v-html="estimate.request_details"></div>
                <br />
                <br />
              </div>
            </div>
          </div>
          <!-- /box -->
        </div>
        <!-- /column -->

        <div class="column">
          <div class="box">
            <h5>{{ $t("RFQS.RESPONSE.FILES_FROM_RESPONSE") }}</h5>
            <hr />
            <slate-file-attachments
              parent-type="vendorestimateresponse"
              :parent-fk="response.id"
              ref="responseFileArea"
            />

            <h5>{{ $t("RFQS.RESPONSE.UPLOAD_FILES") }}</h5>
            <hr />
            <slate-file-uploads
              parent-type="vendorestimateresponse"
              :parent-fk="response.id"
              ref="responseUploader"
            />
            <div
              v-if="
                $refs.responseUploader &&
                $refs.responseUploader.filesToUpload.length
              "
            >
              <br />
              <button
                class="button is-primary is-fullwidth"
                @click="saveData()"
                :disabled="saving || !response.isEditable"
              >
                <icon v-if="saving" icon="spinner fa-spin" />
                <icon v-else icon="upload" />
                <span v-if="saving">{{ $t("APP.UPLOADING") }}</span>
                <span v-else>{{ $t("APP.UPLOAD") }}</span>
              </button>
            </div>

            <br /><br />
            <h5>{{ $t("APP.DISCUSSIONS") }}</h5>
            <hr />
            <slate-discussions
              parent-type="vendorestimateresponse"
              :parent-fk="response.id"
            />
          </div>
          <!-- /box -->
        </div>
        <!-- /column -->
      </section>
    </template>

    <template v-slot:footer>
      <section class="columns">
        <div
          class="column is-2"
          v-if="response.validStatusChoices.includes('declined')"
        >
          <button
            class="button is-danger is-large is-fullwidth"
            @click="saveDeclineData()"
            :disabled="saving || !response.isEditable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="frown" />
            <span>{{ $t("RFQS.RESPONSE.DECLINED.decline") }}</span></button
          >&nbsp;&nbsp;
        </div>
        <div class="column"></div>
        <div class="column is-2">
          <button
            class="button is-primary is-large is-fullwidth"
            @click="saveData()"
            :disabled="saving || !response.isEditable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="save" />
            <span v-if="saving">{{ $t("APP.SAVING") }}</span>
            <span v-else>{{ $t("APP.SAVE") }}</span>
          </button>
        </div>
        <div
          class="column is-2"
          v-if="response.validStatusChoices.includes('ready')"
        >
          <button
            class="button is-success is-large is-fullwidth"
            @click="saveSubmitData()"
            :disabled="saving || !response.isEditable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="share-square" />
            <span v-if="saving">{{ $t("APP.SAVING") }}</span>
            <span v-else>{{ $t("APP.SUBMIT") }}</span>

            <span></span>
          </button>
        </div>
      </section>
    </template>
  </page>
</template>

<script>
import VendorEstimate from '@/api/estimates/vendorEstimateDetail';
import VendorEstimateResponse from '@/api/estimates/vendorEstimateResponseDetail';

import slateDiscussions from '@/modules/discussions/embedded/discussion.vue';
import slateFileAttachments from '@/modules/filebrowser/attachments/filebrowser.vue';
import slateFileUploads from '@/modules/filebrowser/uploader/filebrowser.uploader.vue';

export default {
  name: 'SlateVendorEstimateResponseEdit',
  props: ['id'],
  components: {
    // slatePhoto,
    slateDiscussions,
    slateFileAttachments,
    slateFileUploads,
  },
  data: () => ({
    loading: false,
    errorLoading: false,
    saving: false,
    errorSaving: false,
    saveTimeout: null,
    saveTimeoutSnack: null,

    minDate: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000), // 3 days out.

    response: new VendorEstimateResponse(),
    estimate: new VendorEstimate(),
  }),
  methods: {
    getData(id) {
      this.loading = true;
      if (!(this.response instanceof VendorEstimateResponse)) {
        this.response = new VendorEstimateResponse();
      }
      if (!(this.estimate instanceof VendorEstimate)) {
        this.estimate = new VendorEstimate();
      }
      this.response.loadFromServer(id).then(() => {
        this.estimate.loadFromServer(this.response.estimate).then(() => {
          this.loading = false;
        });
      });
    },
    saveData() {
      if (!this.response.isEditable) {
        return false;
      }
      this.saving = true;

      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }
      this.saveTimeout = setTimeout(this.alertSaveTimeout, 8000);

      return this.response
        .saveToServer()
        .then(() => {
          if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
            this.saveTimeout = null;
          }
          if (this.saveTimeoutSnack) {
            this.saveTimeoutSnack.close();
          }
          this.$refs.responseUploader
            .upload()
            .then(() => {
              setTimeout(() => {
                this.$refs.estimateFileArea.updateData();
                this.$refs.responseFileArea.updateData();

                this.saving = false;
                this.$buefy.snackbar.open({
                  message: 'Response Saved',
                  queue: false,
                });
              }, 300);
            })
            .catch(() => {
              if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
                this.saveTimeout = null;
              }
              if (this.saveTimeoutSnack) {
                this.saveTimeoutSnack.close();
              }

              this.error = true;
              this.saving = false;
              this.$buefy.snackbar.open({
                message: 'Error Uploading',
                type: 'is-danger',
                queue: false,
              });
            });
        })
        .catch((error) => {
          if (error.cancelled) {
            return false;
          }
          this.error = true;
          this.saving = false;
          this.$buefy.snackbar.open({
            message: 'Error Saving',
            type: 'is-danger',
            queue: false,
          });
          return false;
        });
    },
    retrySaveData() {
      if (this.saveTimeoutSnack) {
        this.saveTimeoutSnack.close();
        this.saveTimeoutSnack = null;
      }
      this.saveData();
    },
    alertSaveTimeout() {
      this.saveTimeoutSnack = this.$buefy.snackbar.open({
        message: this.$t('APP.SAVE_TIMEOUT'),
        actionText: this.$t('APP.RETRY'),
        type: 'is-danger',
        queue: false,
        indefinite: true,
        onAction: this.retrySaveData,
      });
    },
    saveSubmitData() {
      this.response.status = 'ready';
      return this.saveData();
    },
    saveDeclineData() {
      this.response.status = 'declined';
      // eslint-disable-next-line no-alert
      this.response.declined_notes = prompt(this.$t('RFQS.RESPONSE.DECLINED.declined_reason'));
      return this.saveData();
    },
  },
  created() {
    this.getData(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.obj-details {
  margin-bottom: calc(2.4rem - 0.75rem);
}
</style>
