// import axios from 'axios';

import SlateAuth from '@/api/auth/auth';
import BaseApiDetails from '../baseApiDetailsClass';


export default class VendorEstimateDetails extends BaseApiDetails {
  fields = [
    { name: 'id' },

    { name: 'status', def: 'draft' },
    { name: 'name' },
    { name: 'number', readonly: true },

    { name: 'priority' },

    { name: 'project' },
    { name: 'project_info', readonly: true },

    { name: 'order' },
    { name: 'order_info', readonly: true },
    { name: 'sales_mgr' },
    { name: 'sales_mgr_info', readonly: true },
    { name: 'proj_mgr' },
    { name: 'proj_mgr_info', readonly: true },

    { name: 'quote_due', type: 'date' },

    { name: 'request_details' },
    { name: 'updated', readonly: true, type: 'dateTime' },
    { name: 'created', readonly: true, type: 'dateTime' },
    { name: 'created_by', readonly: true },
    { name: 'created_by_info', readonly: true },
  ];
  url = 'estimates/vendor-estimate/';

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }

  availStatusChoices = ['draft', 'ready', 'approval', 'approved', 'sent', 'complete', 'cancelled', 'exception_hold'];
  get validStatusChoices() {
    const choices = [this.baseStatus];
    if (this.baseStatus === 'draft') { choices.push('pending', 'declined'); }
    if (this.baseStatus === 'pending') { choices.push('ready', 'declined'); }
    if (SlateAuth.perms.rfq_response_can_set_approval) {
      if (this.baseStatus === 'ready') { choices.push('accepted', 'rejected'); }
    }
    return choices;
  }

  loadFromPayload(payload) {
    super.loadFromPayload(payload);
    this.baseStatus = this.status;
  }

  get isEditable() {
    if (['accepted', 'rejected', 'declined'].includes(this.baseStatus)) {
      return false;
    }
    return true;
  }

  get isVoidable() {
    if (!['pending', 'ready', 'rejected'].includes(this.baseStatus)) {
      return false;
    }
    return true;
  }
}
