// import axios from 'axios';

import SlateAuth from '@/api/auth/auth';
import BaseApiDetails from '../baseApiDetailsClass';


export default class VendorEstimateResponseDetails extends BaseApiDetails {
  fields = [
    { name: 'id' },

    { name: 'status', def: 'draft' },
    { name: 'estimate' },
    { name: 'estimate_info', readonly: true },

    { name: 'client' },
    { name: 'client_info', readonly: true },

    { name: 'vendor' },
    { name: 'vendor_info', readonly: true },
    { name: 'notes' },

    { name: 'updated', readonly: true, type: 'date' },
    { name: 'created', readonly: true, type: 'date' },
    { name: 'created_by', readonly: true },
    { name: 'created_by_info', readonly: true },
  ];
  url = 'estimates/vendor-estimate-response/';

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }

  availStatusChoices = ['draft', 'pending', 'ready', 'declined', 'accepted', 'rejected', 'expired', 'cancelled', 'void'];
  get validStatusChoices() {
    const choices = [this.baseStatus];
    if (this.baseStatus === 'draft') { choices.push('pending', 'ready', 'declined'); }
    if (this.baseStatus === 'pending') { choices.push('ready', 'declined'); }
    if (SlateAuth.perms.rfq_response_can_set_approval) {
      if (this.baseStatus === 'ready') { choices.push('accepted', 'rejected'); }
    }
    return choices;
  }

  loadFromPayload(payload) {
    super.loadFromPayload(payload);
    this.baseStatus = this.status;
  }

  get isEditable() {
    if (['accepted', 'rejected', 'declined'].includes(this.baseStatus)) {
      return false;
    }
    return true;
  }

  get isVoidable() {
    if (!['pending', 'ready', 'rejected'].includes(this.baseStatus)) {
      return false;
    }
    return true;
  }
}
